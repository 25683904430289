import React from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import GallerySlide from "../components/GallerySlide/GallerySlide"
import SectionHeadingH2 from "../components/SectionHeadingH2/SectionHeadingH2"
import photoCalidades from "../images/promociones/Edificio Maestro Bosco/Calidades/calidades.jpg"
import photoSituacion from "../images/promociones/Edificio Maestro Bosco/Situacion/situacion.jpg"

const MaestroBoscoPage = () => {
  const images = useStaticQuery(graphql`
    query {
      photos1CMaestroBosco: allFile(
        filter: {
          relativeDirectory: { eq: "promociones/Edificio Maestro Bosco/1º C" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
      photos2AMaestroBosco: allFile(
        filter: {
          relativeDirectory: { eq: "promociones/Edificio Maestro Bosco/2º A" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
      photos2CMaestroBosco: allFile(
        filter: {
          relativeDirectory: { eq: "promociones/Edificio Maestro Bosco/2º C" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
      photosAtico3DMaestroBosco: allFile(
        filter: {
          relativeDirectory: {
            eq: "promociones/Edificio Maestro Bosco/Atico 3º D"
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
      photosAtico4CMaestroBosco: allFile(
        filter: {
          relativeDirectory: {
            eq: "promociones/Edificio Maestro Bosco/Atico 4º C"
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
      photosZonasComunesMaestroBosco: allFile(
        filter: {
          relativeDirectory: {
            eq: "promociones/Edificio Maestro Bosco/Zonas comunes"
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
    }
  `)

  const photos1C = images.photos1CMaestroBosco.nodes.map(node => {
    return {
      image: node.childImageSharp.fluid,
      imageThumbnail: `${node.childImageSharp.fluid.src}`,
      imageModal: `${node.childImageSharp.fluid.src}`,
      id: node.childImageSharp.id,
    }
  })

  const photos2A = images.photos2AMaestroBosco.nodes.map(node => {
    return {
      image: node.childImageSharp.fluid,
      imageThumbnail: `${node.childImageSharp.fluid.src}`,
      imageModal: `${node.childImageSharp.fluid.src}`,
      id: node.childImageSharp.id,
    }
  })

  const photos2C = images.photos2CMaestroBosco.nodes.map(node => {
    return {
      image: node.childImageSharp.fluid,
      imageThumbnail: `${node.childImageSharp.fluid.src}`,
      imageModal: `${node.childImageSharp.fluid.src}`,
      id: node.childImageSharp.id,
    }
  })

  const photosAtico3D = images.photosAtico3DMaestroBosco.nodes.map(node => {
    return {
      image: node.childImageSharp.fluid,
      imageThumbnail: `${node.childImageSharp.fluid.src}`,
      imageModal: `${node.childImageSharp.fluid.src}`,
      id: node.childImageSharp.id,
    }
  })

  const photosAtico4C = images.photosAtico4CMaestroBosco.nodes.map(node => {
    return {
      image: node.childImageSharp.fluid,
      imageThumbnail: `${node.childImageSharp.fluid.src}`,
      imageModal: `${node.childImageSharp.fluid.src}`,
      id: node.childImageSharp.id,
    }
  })

  const photosZonasComunes = images.photosZonasComunesMaestroBosco.nodes.map(
    node => {
      return {
        image: node.childImageSharp.fluid,
        imageThumbnail: `${node.childImageSharp.fluid.src}`,
        imageModal: `${node.childImageSharp.fluid.src}`,
        id: node.childImageSharp.id,
      }
    }
  )

  return (
    <Layout>
      <div className="container">
        <SectionHeadingH2 sectionTitle="Edificio Maestro Bosco" />
        <div className="text-component max-width-sm margin-x-auto">
          <p>
            Situado en una de las mejores zonas de Puertollano encontrará este
            exclusivo edificio formado por 21 viviendas de lujo de 2 y 3
            dormitorios.
          </p>
          <p>
            La amplitud de las distintas estancias, la luz y la calidez son las
            características que mejor definen esta promoción donde cada vivienda
            es la materialización de sus sueños.
          </p>
        </div>
        <div className="grid gap-xl margin-y-xl">
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">1º C</p>
            <GallerySlide
              gallerySlideListItems={photos1C}
              idLightbox="photos1C"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">2º A</p>
            <GallerySlide
              gallerySlideListItems={photos2A}
              idLightbox="photos2A"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">2º C</p>
            <GallerySlide
              gallerySlideListItems={photos2C}
              idLightbox="photos2C"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Atico 3º D
            </p>
            <GallerySlide
              gallerySlideListItems={photosAtico3D}
              idLightbox="photosAtico3D"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Atico 4º C
            </p>
            <GallerySlide
              gallerySlideListItems={photosAtico4C}
              idLightbox="photosAtico4C"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Zonas comunes
            </p>
            <GallerySlide
              gallerySlideListItems={photosZonasComunes}
              idLightbox="photosZonasComunes"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Calidades
            </p>
            <img src={photoCalidades} alt="" />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Ubicación
            </p>
            <img src={photoSituacion} alt="" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MaestroBoscoPage
